import React from 'react';
import { useNavigate } from 'react-router-dom';

const BotaoNavegacao = ({ texto, destino, imagem }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(destino);
  };

  return (
    <button onClick={handleButtonClick} style={{ ...styles.button, backgroundImage: `url(${imagem})` }}>
      {texto}
    </button>
  );
};

const styles = {
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    borderRadius: '5px',
    border: 'none',
    color: '#FFFFFF',
    backgroundColor: 'green',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
};

export default BotaoNavegacao;
