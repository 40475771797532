import React from 'react';

const Title = ({titulo}) => {
  return (
    <div className='titulo'>
        <h1 style={{fontSize:'50px',letterSpacing:'5px'}}>{titulo}</h1>
    </div>
  );
};

export default Title;
