import React from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import Logo from '../imagens/logo-menu.png'
import Botão from './Botão'

function Navbar() {
  return (
    <nav className='menu'>
        <img src={Logo} alt='Logo Menu'/>
            <ul className='menu-itens'>
                <li><Link to="/">Inicio</Link></li>
                <li><Link to="/sobre">A CooperBem</Link></li>
                <li><Link to="/servicos">Nossos Serviços</Link></li>
            </ul>
        <Botão   texto="CONTATOS"  destino="/contatos" />
    </nav>
  );
}

export default Navbar;
